import React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from 'src/lib/utils';
import { Badge } from 'src/components/ui/badge';
import { RequestResponse } from 'src/lib/services/api/request-api';
import { useTranslation } from 'react-i18next';
import { requestStatusTranslationsMap } from 'src/features/requests/request.helper';

const requestBadgeVariants = cva('tw-rounded-lg tw-border-transparent tw-font-medium', {
  variants: {
    variant: {
      draft: 'tw-bg-blue-300 tw-text-blue-500',
      queued: 'tw-bg-neutral-200 tw-text-neutral-700',
      currently_editing: 'tw-bg-yellow-300 tw-text-yellow-900',
      delivered: 'tw-bg-purple-200 tw-text-brand',
      complete: 'tw-bg-green-200 tw-text-green-900',
      archived: 'tw-bg-orange-200 tw-text-orange-900',
      unknown: 'tw-bg-neutral-200 tw-text-neutral-700',
    } as { [key: RequestResponse['status'] | string]: string },
    size: {
      sm: 'tw-px-2 tw-py-1 tw-text-sm',
      default: 'tw-px-4 tw-py-1 tw-text-base',
    },
  },
  defaultVariants: {
    variant: 'unknown',
    size: 'default',
  },
});

type RequestStatusBadgeVariantProps = VariantProps<typeof requestBadgeVariants>;

export type RequestStatusBadgeProps = React.HTMLAttributes<HTMLDivElement> &
  Omit<RequestStatusBadgeVariantProps, 'variant'> & {
    variant?: string | RequestStatusBadgeVariantProps['variant'];
  };

const variants: RequestStatusBadgeProps['variant'][] = [
  'draft',
  'queued',
  'currently_editing',
  'delivered',
  'complete',
  'archived',
];

function RequestStatusBadge({
  className,
  size,
  variant,
  children,
  ...props
}: RequestStatusBadgeProps) {
  const { t } = useTranslation();

  return (
    <Badge
      variant={'unset'}
      className={cn(
        requestBadgeVariants({ size, variant: variants?.includes(variant) ? variant : 'unknown' }),
        className,
      )}
      {...props}
    >
      {children ?? t(requestStatusTranslationsMap(`${variant}`))}
    </Badge>
  );
}
export { RequestStatusBadge };
