import { useLocation } from 'react-router-dom';
import { storage } from 'src/lib/utils';

let used = false;

type LocationObject = ReturnType<typeof useLocation>;

export const usePreLoginAuthLocation = () => {
  const rememberLocation = (object: LocationObject) => {
    if (used) {
      return object;
    }

    storage.set('intendedPreLoginAuthLocation', object);
    return object;
  };

  const getLocation = (fallback: LocationObject) => {
    const result = storage.get<LocationObject>('intendedPreLoginAuthLocation', true);
    storage.remove('intendedPreLoginAuthLocation');
    used = true;
    return result || fallback;
  };

  return { rememberLocation, getLocation };
};
