import { useCallback } from 'react';
import { MediaObject } from 'src/api/services/MediaClient';
import { multiDownload } from 'src/lib/utils';
import { useToggle } from 'src/lib/state-utils';

const useMediaDownload = () => {
  const [isDownloading, , toggle] = useToggle();

  const downloadAll = useCallback(async (media: MediaObject[]) => {
    toggle();

    const urls = media.map((m) => m.index_url).filter((url) => url);

    await multiDownload(urls);

    toggle();
  }, []);

  return {
    downloadAll,
    isDownloading,
  };
};

export { useMediaDownload };
